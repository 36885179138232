import { apiPath } from "@/config/apiPath";
import axios from "@/utils/axios";
import { getQueryString } from "@/utils/helper";

const state = {
  pageAttributes: {
    page: 1,
    itemsPerPage: 10,
  },
  webQueries: [],
};

const getters = {
  getAllWebQueries: (state) => state.webQueries,
};

const mutations = {
  doNothing: (state) => state,
  setAllWebQueries: (state, payload) => (state.webQueries = payload),
};

const actions = {
  /**
   * @description Get All User
   * @param {Number} data - Users Object
   * @returns Promise
   */
  async fetchAllWebQueries({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { page, itemsPerPage } = options;
        params = {
          page: page,
          limit: itemsPerPage,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.webQueries.getAllWebQueries+`?${url}`)
        .then(async (res) => {
          if (res.data.status) {
            await commit("setAllWebQueries", res.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
